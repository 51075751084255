<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Transactions ({{ transactions.meta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <template>
        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>

      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left">Reference Number</th>
            <th width="20%" class="text-left">Status</th>
            <th width="20%" class="text-left">Type</th>
            <th width="20%" class="text-left">Amount</th>
            <th width="20%" class="text-left">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactions.data" :key="transaction.id">
            <td>
              {{ transaction.reference_number }}
            </td>
            <td>
              <p
                v-if="transaction.payment_status === 'complete'"
                class="green--text ma-0"
              >
                {{ transaction.payment_status }}
              </p>
              <p v-else class="red--text ma-0">
                {{ transaction.payment_status }}
              </p>
            </td>
            <td>
              {{ transaction.transaction_type }}
            </td>
            <td>{{ transaction.amount }}</td>
            <td>{{ transaction.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
      <div v-if="transactions.data">
        <div
          class="d-flex justify-center mt-3"
          v-if="!(transactions.data.length > 0) && !loading"
        >
          <p>No match found.</p>
        </div>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'TransactionsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
        transaction_type: 'purchased-program',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      sortOrder: true,
    }
  },

  created() {
    this.clearTransactions()
    this.fetchTransactions()
  },

  computed: {
    ...mapState({
      transactions: (state) => state.transaction.transactions,
    }),
  },

  methods: {
    ...mapActions({
      getTransactions: 'transaction/getTransactions',
      exportToExcel: 'transaction/exportToExcel',
    }),

    ...mapMutations({
      clearTransactions: 'transaction/clearTransactionList',
    }),

    pageChanged(page) {
      this.fetchTransactions(page)
    },

    filterUser(filterValue) {
      this.clearTransactions()
      this.fetchTransactions(null, filterValue)
    },

    loadMore() {
      if (
        this.transactions?.meta?.current_page <
        this.transactions?.meta?.last_page
      ) {
        this.getTransactions({
          loadMore: true,
          page: this.transactions.meta.current_page + 1,
          sort: this.sortOrder
            ? `${this.defaultFilter}`
            : `-${this.defaultFilter}`,
          limit: 20,
        })
      }
    },

    search: debounce(function () {
      this.clearTransactions()
      this.fetchTransactions()
    }, 600),

    async fetchTransactions(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
        limit: 20,
      }
      params.filter = this.filter

      this.loading = true
      await this.getTransactions(params)
      this.loading = false
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel(this.filter)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'purchased-transactions')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
  },
}
</script>
